<template lang="pug">
.league
  v-container
    v-row.table_row
      v-col.table_col.table.expansion_table.position-relative.mt-5(
        cols="12",
        :class="{ expand: isTeamRankingExpand }"
      )
        table
          thead
            tr.bottom_head
              sticky-column(
                style="width: 70px; min-width: 70px",
                :title="'排名'",
                :offset="0"
              )
              sticky-column.bdr2.text-center(
                style="width: 120px; min-width: 120px",
                :title="'球隊'",
                :offset="70"
              )
              th.bdr 場次
              th.bdr 勝
              th.bdr 敗
              th.bdr 勝率
              th.bdr 勝差
              th.bdr 連勝/連敗
          tbody.extension
            tr.hover(
              v-if="isTeamRankingExpand ? true : index < teamRankingDefaultAmount + 1",
              v-for="(team, index) in teamRankingList",
              :key="index",
              style="cursor: pointer"
            )
              sticky-column(style="width: 70px; min-width: 70px", :offset="0")
                .ranking.text-center(
                  :class="[index < 3 ? 'text_secondary' : 'lightGray']"
                ) {{ getRankText(index + 1) }}
              sticky-column.bdr2(
                style="width: 120px; min-width: 120px",
                :offset="70"
              )
                .team_container.d-flex.align-center
                  img(width="30px", v-if="team.photo", :src="team.photo.image")
                  span.ml-1 {{ team.info.name }}

              td.bdr {{ team.info.record_matches }}
              td.bdr {{ team.info.wins }}
              td.bdr {{ team.info.losses }}
              td.bdr {{ (team.info.win_rate * 100).toFixed(1) }}%
              td.bdr {{ team.info.game_behind }}
              td {{ getStreakText(team.info.winning_streak) }}
        .expand_btn(@click="expand", tile)
          p {{ isTeamRankingExpand ? '顯示較少' : '展開全部' }}
            span
              font-awesome-icon.ml-2(:icon="['fas', 'angle-double-down']")
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";

export default {
  name: "Table",
  components: {
    StickyColumn,
  },
  data() {
    return {
      isTeamRankingExpand: false,
      teamRankingList: [
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
        {
          info: {
            record_matches: 1,
            wins: 1,
            losses: 1,
            win_rate: 1,
            game_behind: 1,
            winning_streak: 1,
          },
        },
      ],
      teamRankingDefaultAmount: 5,
    };
  },
  methods: {
    expand() {
      this.isTeamRankingExpand = !this.isTeamRankingExpand;
    },
    getStreakText(count) {
      if (count > 1) {
        return count + "連勝";
      } else if (count < -1) {
        return 0 - count + "連敗";
      } else {
        return "-";
      }
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
</style>
